import React, { useState } from "react"
import * as styles from "./heroSecondary.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"
import Link from "gatsby-link";
// import ModalVideo from "react-modal-video"
import { GrPlayFill } from "react-icons/all"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const HeroSecondary = () => {
  const {
    fileName: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      fileName: file(name: { eq: "claudia@2x" }) {
        childImageSharp {
          fluid(maxWidth: 2180, maxHeight: 2160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.heroSecondary}>
      <div className={`clearfix container ${styles.container}`}>
        <div className={styles.intro}>
          <h1>Starte jetzt! </h1>
          <h1>Ich freue mich auf dich!</h1>
          <div className={btnStyles.buttonGroup} style={{marginTop:20}}>
            <Link to="/checkout" className={`${btnStyles.button} ${btnStyles.buttonBorder}`}>
              Kurs kaufen
            </Link>
          </div>
        </div>
        <div className={styles.boxFirst}></div>
        <div className={styles.boxSecond}></div>
        <div className={styles.image}>
          <Img className={styles.imageWrapper} fluid={fluid}></Img>
        </div>
      </div>
    </div>
  )
}

export default HeroSecondary
