import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Preview from "../components/preview"
import HeroSecondary from "../components/hero/heroSecondary"

import * as styles from "../scss/contactPage.module.scss"

import IconLocation from "../images/Icons/icon_location.svg"
import IconPhone from "../images/Icons/icon_telephone.svg"
import IconEmail from "../images/Icons/icon_email.svg"

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <SEO
        title="Kontakt"
        description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
        keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
        author= "spectory"
        />
        <HeroSecondary />
        <div className="container">
          <Preview
            title="Über mich"
            content="Hallo, mein Name ist Claudia Koch! Ich bin ganzheitliche Therapeutin und arbeite als zertifizierte, professionelle Kinesiologin und internationale Pranatherapeutin. Zu meinem Klientel zählen Manager, Künstler, Therapeuten, Ärzte, genauso wie Studenten und Schüler. Ich unterstützte sie dabei, körperliche Gesundheitsprobleme zu überwinden, emotionale Belastungen und Traumata zu heilen, emotionalen Stress zu reduzieren."
            link=""
            linkType="none"
            linkTitle=""
            sourceType="video"
            source={{
              image:
                "https://detoxme.at/wp/wp-content/uploads/2021/03/IMG-20210308-WA0010@2x.jpg",
              vimeo: "562705288",
            }}
            sourcePosition="left"
          />
        </div>
        <div className={styles.contacts}>
          <div className={`container ${styles.container}`}>
            <div className={styles.contactItem}>
              <IconLocation />
              <div className={styles.contactTitle}>Praxis</div>
              <p>
                <a href="https://www.energiepraxis.com"> www.energiepraxis.com </a>
              </p>
            </div>
            <div className={styles.contactItem}>
              <IconPhone />
              <div className={styles.contactTitle}>Telefon</div>
              <p><a href="tel:+436507598580">0650/7598580</a></p>
            </div>
            <div className={styles.contactItem}>
              <IconEmail />
              <div className={styles.contactTitle}>Mail</div>
              <p><a href="mailto:claudia.koch@energiepraxis.com">claudia.koch@energiepraxis.com</a></p>
            </div>
          </div>
        </div>
        <div className={styles.greenLine}></div>
      </Layout>
    </div>
  )
}

export default ContactPage
